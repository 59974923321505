import * as React from "react"
import {useState, useEffect} from 'react';
import axios from 'axios';
import {Link} from "gatsby"
import Carousel from "react-multi-carousel";

const Bloglist = (props, data) => {
    const [brands, setBrands] = useState(false);
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        axios.get(`${process.env.API_URL}/blogs`).then((res) => {
            setBrands(res.data);
        }).catch((error) => {
            console.log(error);
        });
    };

    const responsive = {
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 4,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: {max: 1024, min: 600},
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: {max: 600, min: 0},
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };
    if (brands === false) {
        return (<>Loading...</>);
    }

    return (
        <Carousel autoPlay className="" responsive={responsive} infiniteLoop={true}>
            {brands.map((r) => (
                <div key={"blog-" + r.id} className="col-md-12 blogpadding">
                    <div className="blogtag">
                        <Link to={"/blog-detail/" + r.slug}>
                            <img className="brandimage"
                                 src={r.image !== null ? `${process.env.API_URL}` + r.image.url : "https://via.placeholder.com/350x200.png?text=" + r.title}
                                 alt={r.title}/>
                            <div className="card-body">
                                <h3 className="retailer">  {r.title}</h3>
                                <p className="blogdetail">  {r.description.slice(0, 200)}...</p>
                                <div className="text-center mt-4 mb-1"><Link to={"/blog-detail/" + r.slug} className="bgbtnblue">Read More</Link></div>
                            </div>
                        </Link>
                    </div>
                </div>
            ))}
        </Carousel>
    )
}

export default Bloglist
